import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { Alert, AppBar, Grid, Link, Toolbar } from "@mui/material";
import { useState } from "react";
import { theme } from "../../Theme";
import Header from "./partials/Header";
import URLForm from "./partials/URLForm";
import Results from "./partials/Results";

export default function Home() {
  const debug = false;
  const [error, setError] = useState<string>();
  const [shortUrl, setShortUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const copyContent = async () => {
    if (shortUrl) {
      try {
        await navigator.clipboard.writeText(shortUrl);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setShortUrl(undefined);
    setError(undefined);
    const data = new FormData(event.currentTarget);
    fetch("/api/shorten", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: data.get("url"),
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setShortUrl(json.url);
        setIsLoading(false);
      })
      .catch((e) => {
        const errorMessage = e instanceof Error ? e.message : JSON.stringify(e);
        setError(errorMessage);
        setIsLoading(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Url Fox
          </Typography>
          {/* TODO: Toggle dark mode */}
          {/* <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
            Login
          </Button> */}
        </Toolbar>
      </AppBar>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Header debug={debug} />
        <URLForm debug={debug} isLoading={isLoading} onSubmit={handleSubmit} />
        <Results
          debug={debug}
          error={error}
          shortUrl={shortUrl}
          onCopyContent={copyContent}
        />
      </Container>
    </ThemeProvider>
  );
}
