import { Alert, Box, Button, Link, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface ResultsProps {
  debug: boolean;
  error?: string;
  shortUrl?: string;
  onCopyContent: () => Promise<void>;
}

function Results(props: ResultsProps) {
  const shortUrl = props.debug ? "https://example.com" : props.shortUrl;
  const error = props.debug ? "Something went wrong!" : props.error;

  return (
    <Box
      width="100%"
      maxWidth="md"
      sx={{
        margin: 2,
        paddingX: 10,
        display: "flex",
        flexDirection: "column",
        border: props.debug ? "solid 1px green" : "none",
      }}
    >
      {shortUrl && (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            href={shortUrl}
            variant="h5"
            sx={{ fontWeight: 500, margin: 2 }}
          >
            {shortUrl}
          </Link>
          <Button
            variant="contained"
            sx={{ margin: 2 }}
            startIcon={<ContentCopyIcon />}
            onClick={props.onCopyContent}
          >
            Copy
          </Button>
        </Box>
      )}
      {error && (
        <Box sx={{ flex: 1 }}>
          <Alert severity="error">Error: {error}</Alert>
        </Box>
      )}
    </Box>
  );
}

export default Results;
