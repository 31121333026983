import { ThemeOptions, createTheme } from "@mui/material";

type ThemeOverrides = { dark: ThemeOptions; light: ThemeOptions };

// Hardcoded to dark. Change here to make it dynamic
const mode = "dark";

const overrides: ThemeOverrides = {
  dark: {
    palette: {
      mode: "dark",
      background: {
        default: "#282f3d",
      },
    },
  },
  light: {
    palette: {
      mode: "light",
      background: {
        default: "#d0dbf2",
      },
    },
  },
};

export const theme = createTheme(overrides[mode]);
