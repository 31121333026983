import { Box, Typography } from "@mui/material";

interface HeaderProps {
  debug: boolean;
}

function Header(props: HeaderProps) {
  return (
    <Box
      sx={{
        width: "100%",
        margin: 2,
        paddingX: 2,
        paddingY: 7,
        textAlign: "center",
        border: props.debug ? "solid 1px green" : "none",
      }}
    >
      <Typography variant="h3" component="h1" sx={{ fontWeight: "800" }}>
        Create short links
      </Typography>
      <Typography variant="h5" sx={{ fontWeight: "500" }}>
        Small paths lead to great journeys
      </Typography>
    </Box>
  );
}

export default Header;
