import { Box, TextField, Button } from "@mui/material";

interface URLFormProps {
  debug: boolean;
  isLoading: boolean;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

function URLForm(props: URLFormProps) {
  return (
    <Box
      component="form"
      onSubmit={props.onSubmit}
      noValidate
      width="100%"
      maxWidth="md"
      sx={{
        margin: 2,
        paddingX: 10,
        display: "flex",
        border: props.debug ? "solid 1px green" : "none",
      }}
    >
      <TextField
        required
        id="url"
        label="Paste your link here"
        name="url"
        autoComplete="url"
        autoFocus
        sx={{ flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        InputProps={{
          sx: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
        }}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{ flex: 0.2, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        disabled={props.isLoading}
      >
        Shrink!
      </Button>
    </Box>
  );
}

export default URLForm;
